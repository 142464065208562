import React, { useEffect } from 'react';

// eslint-disable-next-line import/order
import AOS from 'aos';

import 'aos/dist/aos.css';
import { type NextPage } from 'next';
import { type NextFont } from 'next/dist/compiled/@next/font';

import {
  Hero,
  PageContainer,
  ConnectedAwards,
} from '../components/index';

// eslint-disable-next-line react/prop-types
const Home: NextPage<{ inter: NextFont }> = ({ inter }) => {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanc
      offset: 150, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
    AOS.refresh();
  }, []);
  return (
    <div className="flex w-screen flex-col">
      <PageContainer>
        <Hero font={inter} />
        <ConnectedAwards font={inter} />
      </PageContainer>
    </div>
  );
};

export default Home;
